<template>
  <loader v-if="isLoading" />
  <BaseForm v-else :validate="validateForm" @submit="fetchProfile">
    <BaseInput
      :value="gameTitle"
      :label="$t('games.single')"
      :disabled="true"
    />

    <div v-if="isTeam" class="input-block">
      <div class="input-block-label">{{ $t('teams.single') }} *</div>
      <div class="description">
        {{ $t('jobfair.select_team_title') }}
      </div>
      <BaseSelect
        v-model="form.teamHash"
        :required="true"
        :placeholder="$t('teams.select')"
        :options="teamsList"
        :status.sync="error.team"
        list-position="top"
        label-key="name"
        value-key="teamHash"
      />
    </div>

    <div class="input-block">
      <template v-if="isTeam">
        <div class="input-block-label">{{ $t('global.age') }}</div>
        <div class="description">
          {{ $t('jobfair.select_team_age') }}
        </div>
        <AgeRange
          v-model="form.age"
          :min="dictionary.age.min"
        ></AgeRange>
      </template>
      <template v-else>
        <div class="input-block-label">{{ $t('global.age') }}*</div>
        <div class="description">
          {{ $t('jobfair.select_player_age') }}
        </div>
        <BaseInput
          v-model="form.age"
          type="number"
          :status.sync="error.age"
          :placeholder="$t('jobfair.select_player_age')"
          @change="validateAge"
        />
      </template>
    </div>

    <div
      v-if="roles.length || form.roles.length"
      class="input-block roles"
    >
      <div class="input-block-label">
        {{ $t('players.role_single') }}*
      </div>
      <div class="description">
        {{ $t(`jobfair.select_${isTeam ? 'team' : 'player'}_role`) }}
      </div>
      <BaseSelect
        v-model="form.roles"
        :required="true"
        :placeholder="$t('players.role_select')"
        :limit="4"
        :options="roles"
        :multiple="true"
        label-key="label"
        :status.sync="error.roles"
        list-position="top"
      >
        <template v-slot:option="{ option }">
          <div class="contact-option">
            <Icon group="jobfair" :name="option.value" />
            {{ option.label }}
          </div>
        </template>
      </BaseSelect>
    </div>

    <div class="input-block">
      <div class="input-block-label">{{ $t('global.target') }}*</div>
      <div class="description">
        {{
          $t(`jobfair.select_${isTeam ? 'team' : 'player'}_target`)
        }}
      </div>
      <BaseSelect
        v-model="form.target"
        :required="true"
        :placeholder="$t('players.target_select')"
        :options="targets"
        :status.sync="error.target"
        list-position="top"
        label-key="label"
        value-key="value"
      />
    </div>

    <div class="input-block">
      <div class="input-block-label">
        {{ $t('global.contacts') }}*
      </div>
      <div class="description">
        {{ $t('profile.binding_errors_contacts') }}
      </div>

      <template v-if="addedContacts.length">
        <div
          v-for="contact in addedContacts"
          :key="contact.value"
          class="contact-added"
        >
          <Icon :name="contact.icon" />
          <BaseInput
            v-model="form.contacts[contact.value]"
            :status="form.contacts[contact.value] ? '' : 'error'"
            :placeholder="placholders[contact.value]"
            @input="handleContactInput"
          />
          <pvp-btn
            icon-left="cross"
            variant="clear"
            @click="removeContact(contact)"
          />
        </div>
      </template>

      <BaseSelect
        v-if="contacts.length"
        v-model="addedContacts"
        :required="true"
        :placeholder="$t('jobfair.select_contacts')"
        :multiple="true"
        list-position="top"
        :options="contacts"
        :status.sync="error.contacts"
      >
        <template v-slot:selected-option="{}">
          <span />
        </template>
        <template v-slot:option="{ option }">
          <div class="contact-option">
            <Icon :name="option.icon" />
            {{ option.label }}
          </div>
        </template>
      </BaseSelect>
    </div>

    <div class="input-block">
      <div class="input-block-label">
        {{ $t('global.additionally') }}
      </div>
      <BaseInput
        v-model="form.description"
        :placeholder="$t('inputs.interesting')"
        :maxlength="80"
        :is-area="true"
      />
    </div>

    <div v-if="isEdit" class="input-block">
      <div class="input-block-label">
        {{ $t('jobfair.del_title') }}
      </div>
      <div class="remove-box">
        <div class="remove-description">
          {{ $t('jobfair.del_description') }}
        </div>
        <pvp-btn
          icon-left="cross"
          variant="link"
          @click="$emit('send', 'DELETE')"
        >
          {{ $t('jobfair.del_action') }}
        </pvp-btn>
      </div>
    </div>

    <div class="input-block">
      <div class="input-block-label">{{ $t('jobfair.info') }}</div>
    </div>

    <div class="form-footer">
      <div v-if="error.common" class="error">{{ error.common }}</div>
      <pvp-btn variant="secondary" @click="$emit('send', 'BACK')">
        {{ $t(isEdit ? 'global.cancel' : 'global.back') }}
      </pvp-btn>
      <pvp-btn type="submit">
        {{ $t(isEdit ? 'save.single' : 'global.done') }}
      </pvp-btn>
    </div>
  </BaseForm>
</template>

<script>
import AgeRange from '../BaseComponents/AgeRange.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import { pushEvents } from '@utils/metricEvents.js';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'JobfairSettingsForm',
  components: {
    AgeRange,
    BaseInput,
    Icon,
    BaseForm,
    BaseSelect,
  },
  filters: {
    filterIcon(icon) {
      if (icon) {
        return icon === 'facebook' ? 'fb' : icon;
      }
      return '';
    },
  },
  props: {
    gameId: {
      type: Number,
      required: true,
    },
    cardType: {
      type: String,
      required: true,
    },
    cardId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    addedContacts: [],
    isLoading: false,
    form: {
      teamHash: undefined,
      age: '',
      roles: [],
      target: '',
      contacts: {},
      description: '',
    },
    placholders: {
      vk: 'https://vk.com/id1337',
      facebook: 'https//fb.com/1337',
      discord: 'example#1337',
      skype: 'login',
    },
    error: {
      common: '',
      team: '',
      roles: '',
      target: '',
      contacts: '',
      age: '',
    },
  }),

  computed: {
    ...mapState('jobfair', ['dictionary']),
    ...mapGetters('users', ['getUserTeams']),
    ...mapGetters('jobfair', ['getJobfairProfile']),
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', ['getCurrentProfile']),

    isTeam() {
      return this.cardType === 'vacancy';
    },

    isEdit() {
      return Boolean(this.cardId);
    },

    gameTitle() {
      return this.getGame(this.gameId)?.name;
    },

    roles() {
      return this.dictionary?.roles?.[this.gameId] || [];
    },

    targets() {
      return this.dictionary?.targets || [];
    },

    contacts() {
      return (
        this.dictionary?.contacts?.map((item) => ({
          ...item,
          icon: { facebook: 'fb' }[item.value] || item.value,
        })) || []
      );
    },

    profile() {
      return this.getCurrentProfile;
    },

    teamsList() {
      const teamsList = this.getUserTeams(this.profile.hash) || [];
      return teamsList?.filter(
        ({ idGame, isCaptain }) =>
          idGame === this.gameId && isCaptain,
      );
    },
  },

  created() {
    if (this.isEdit) {
      this.setDefaultEditSettings();
    } else {
      this.setDefaultSettings();
    }
  },

  methods: {
    ...mapActions('profile', ['fetchProfileGames']),

    setDefaultEditSettings() {
      const profile = this.getJobfairProfile(this.cardId) || {};
      const rolesList = this.dictionary?.roles?.[this.gameId] || [];

      const roles =
        profile.roles?.map(({ id }) =>
          rolesList.find((role) => role.id === id),
        ) || [];
      const age = this.isTeam
        ? [profile.ageMin, profile.ageMax]
        : profile.age;

      const contacts = {};
      this.addedContacts = profile.contacts?.map(
        ({ id, contactValue }) => {
          const contact = this.contacts?.find(
            ({ id: _id }) => id === _id,
          );
          contacts[contact.value] = contactValue;
          return contact;
        },
      );

      this.form = {
        ...this.form,
        age,
        roles,
        contacts,
        target: profile.target.targetType,
        description: profile.description,
        teamHash: profile.teamHash,
      };
    },

    setDefaultSettings() {
      const { discord } = this.profile?.messengers || {};

      const setContact = (type, value) => {
        this.form.contacts[type] = value;
        this.addedContacts.push(
          this.contacts?.find(({ value }) => value === type),
        );
      };

      if (discord) {
        setContact('discord', discord);
      }

      if (this.isTeam) {
        this.form.age = [
          this.dictionary?.age?.min,
          this.dictionary?.age?.max,
        ];
      } else {
        try {
          if (this.profile.birthdate) {
            this.form.age =
              new Date(
                Date.now() -
                  new Date(this.profile.birthdate).getTime(),
              ).getFullYear() - 1970;
          }
        } catch (e) {
          this.form.age = '';
        }
      }
    },

    removeContact({ value }) {
      this.error.contacts = '';
      this.addedContacts = this.addedContacts.filter(
        (item) => item.value !== value,
      );
      this.form.contacts[value] = undefined;
    },

    onBack() {
      this.$emit('change', {
        state: this.isEdit ? 'close' : 'prevState',
      });
    },

    validateAge() {
      const minAge = 0;
      const maxAge = this.dictionary?.age?.max || 100;
      if (this.form.age < minAge) {
        this.form.age = minAge;
      }
      if (this.form.age > maxAge) {
        this.form.age = maxAge;
      }
    },

    validateForm() {
      this.error.team =
        this.isTeam && !this.form.teamHash ? 'error' : '';
      this.error.roles = this.form.roles?.length === 0 ? 'error' : '';
      this.error.target = !this.form.target ? 'error' : '';
      this.error.contacts =
        this.addedContacts?.length === 0 ? 'error' : '';

      if (this.isTeam === false) {
        this.error.age = this.form.age?.length === 0 ? 'error' : '';
      }

      const hasContacts =
        Object.values(this.form.contacts).filter(Boolean).length > 0;
      const { ...errors } = this.error;
      return (
        !Object.values(errors).filter((error) => error).length &&
        hasContacts
      );
    },

    fetchProfile() {
      const { age, roles, teamHash, ...form } = this.form;

      if (this.isTeam) {
        const [minAge, maxAge] = age;
        form.minAge = +minAge;
        form.maxAge = +maxAge;
        form.teamHash = teamHash;
      } else {
        form.age = +age;
      }

      if (this.isEdit) {
        form.id = this.cardId;
      }

      this.error = {
        common: '',
        team: '',
        roles: '',
        target: '',
        contacts: '',
      };

      this.isLoading = true;
      api
        .post(`/declaration/${this.isEdit ? 'update' : 'create'}`, {
          ...form,
          gameId: this.gameId,
          type: this.cardType,
          roles: roles?.map(({ value }) => value) || [],
        })
        .then((data) => {
          if (data.success) {
            if (!this.isEdit && this.cardType === 'vacancy') {
              // ======================================================
              // METRICS Пользователь создал анкету по поиску игроков
              const tmrParams = {
                category: 'pvp',
                action: 'teamfinder_teammates',
                label: this.getGame(this.gameId).name,
              };
              pushEvents('pvp_teamfinder_teammates', tmrParams);
              // ======================================================
            } else {
              // ======================================================
              // METRICS Пользователь создал анкету по поиску команды
              const tmrParams = {
                category: 'pvp',
                action: 'teamfinder_team',
                label: this.getGame(this.gameId).name,
              };
              pushEvents('pvp_teamfinder_team', tmrParams);
              // ======================================================
            }

            this.$emit('send', 'NEXT', {
              teamName: this.teamsList?.find(
                (data) => data.teamHash === teamHash,
              )?.name,
            });
          }
          if (data.errors?.length) {
            this.error.common = data.errors.join(', ');
          }
        })
        .catch(({ error }) => {
          if (error?.length) {
            this.error.common = error.join(', ');
          } else {
            this.error.common = this.$t('errors.unknown');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleContactInput() {
      this.error = {
        common: '',
        team: '',
        roles: '',
        target: '',
        contacts: '',
        age: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.input-block {
  margin-top: 1em;

  &-label {
    color: rgba(white, 0.5);
    font-size: em(14px);
    line-height: 1;
    margin-bottom: 8px;
  }

  .description {
    font-size: em(12px);
    color: rgba(white, 0.5);
    line-height: 1;
    margin-bottom: 8px;
  }
}

.age-range {
  margin-top: 8px;
}

.contact {
  &-added {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    .label {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }

    .button {
      color: rgba(white, 0.5);
    }
  }

  &-option {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 0.5em;
    }
  }
}

.remove {
  &-box {
    background-color: rgba(black, 0.1);
    padding: 15px 30px;
  }
}

.error {
  color: $orangey-red;
  font-size: 14px;
  text-align: center;
  margin-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
}
</style>
